<template>
  <div id="main-container" :key="componentKey">

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">

      <!--   Spacer   -->
      <div class="pt-5"></div>
      <!--   our services section   -->
      <div id="ourServices_id" class="text-center  mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">{{ $t('Home.OurServices') }}</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="trophy"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
<!--        <div>
          <h3>The Buddha Said, “It Is Not The Number Of Blog Tips You Offer But The Quality.”</h3>
        </div>-->
      </div>

      <div v-if="services != null">
        <div class="pt-4"></div>

        <div v-if="urdudata == false" class="row">
          <div v-for="data in services" :key="data.id" class="col-lg-4 mb-3">
            <router-link :to="{ name: 'ServiceDetail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
              <div  class="mt-2 mb-2 custom-row align-items-start">
            <span class="mr-5 mt-2 mb-2" >
<!--              <div class="text-center">
                <div class="custom-card-icon custom-service-icon">
                  <b-icon icon="building"></b-icon>
                </div>

              </div>-->

              <img :src="url+data.videoOrImage" alt=""
                   class="w3-left w3-circle w3-margin-right"
                   style="width: 80px; height: 80px;object-fit: contain;background-color: #fff">
            </span>

                <div class="flex-grow-1">
                  <div class="custom-widget">
                    <h3 class="widget-title m-0 mb-1">{{ data.title }}</h3>
                  </div>

                  <p class="custom-service-text mb-0">
                    {{ data.description }}...
                  </p>
                </div>

              </div>
              <div class="row no-gutters">
                <div class="col-lg-9"></div>
                <div class="col-lg-3">Read More</div>
              </div>
            </router-link>

          </div>

        </div>
        <div v-else class="row">
          <div v-for="data in services" :key="data.id" class="col-lg-4 mb-3">
            <router-link :to="{ name: 'ServiceDetail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
              <div  class="mt-2 mb-2 custom-row align-items-start">
            <span class="mr-5 mt-2 mb-2" >
<!--              <div class="text-center">
                <div class="custom-card-icon custom-service-icon">
                  <b-icon icon="building"></b-icon>
                </div>

              </div>-->

              <img :src="url+data.videoOrImage" alt=""
                   class="w3-left w3-circle w3-margin-right"
                   style="width: 80px; height: 80px;object-fit: contain;background-color: #fff">
            </span>

                <div class="flex-grow-1">
                  <div class="custom-widget">
                    <h3 class="widget-title m-0 mb-1">{{ data.urduTitle }}</h3>
                  </div>

                  <p class="custom-service-text mb-0">
                    {{ data.urduDescription }}...
                  </p>
                </div>

              </div>
              <div class="row no-gutters">
                <div class="col-lg-9"></div>
                <div class="col-lg-3"> {{ $t('Main.ReadMore') }}</div>
              </div>
            </router-link>

          </div>

        </div>

        <div class="text-center clear-bottom">
          <div class="row no-gutters">
            <div class="col-lg-4"></div>

            <div class="col-lg-4">
              <router-link :to="{ name: 'ContactUs' }"

                           class="btn link-theme text-decoration-none">Contact Us</router-link>
            </div>
            <div class="col-lg-4"></div>
          </div>

        </div>

        <!--   Spacer   -->
        <div class="pt-5"></div>
        <!--  Load more    -->
<!--        <div>
          <div class="text-center clear-bottom">
            <router-link to="/"class="btn link-theme text-decoration-none" href="#">
              <i aria-hidden="true" class="fa fa-plus-circle"> </i> View More</router-link
            >
          </div>
        </div>-->
      </div>

    </div>

    <!-- Complete profile Modal-->
    <div class="">

    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'AllServices',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      urdudata:false,
      list: null,
      PageOfItems: [],

      option: 0,

      // Dynamic Content Start

      servicesModel: {
        PageSize: 0,
        PageNumber: 1,
        Text: ''
      },

      services: [],

      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  // carousel_latest_jobs
  methods: {
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getServices () {
      this.rerender()

      this.services = []
      axios.post('/api/Shehri/GetVentureServices', this.servicesModel)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }

          const list = res.data.data
          this.services = list.slice().reverse()

          console.log('ventureher services : ', this.services)
          // let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

          /* for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          */
          console.log('id : ', this.$route.params.id)
          if (this.$route.params.id != undefined) {
            this.scrollTo(this.$route.params.id)
          }
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    // Rerender Component
    rerender () {
      this.componentKey += 1
    }

  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        debugger
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  mounted () {
    this.getServices();
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
  }
}
</script>
